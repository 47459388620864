import React from "react"

import styles from "./social_badge.module.css"

const SocialBadge = ({ name, url, logo }) => (
  <a className={styles.badge} href={url}>
    <img alt={name} src={logo} width="24" height="24" />
  </a>
)

export default SocialBadge
