import React from "react"

import BusinessCard from "../components/business_card"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO />
    <BusinessCard />
  </Layout>
)

export default IndexPage
