import React from "react"
import styles from "./business_card.module.css"

import "typeface-gochi-hand"

import SocialBadge from "./social_badge"

import linkedinLogo from "../images/linkedin.svg"
import githubLogo from "../images/github.svg"

const data = {
  socialLinks: [
    {
      name: "github",
      url: "https://github.com/jezstephens",
      logo: githubLogo,
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/jezstephens",
      logo: linkedinLogo,
    },
  ],
}

const BusinessCard = () => (
  <div className={styles.businessCard}>
    <h1 className={styles.name}>Jez Stephens</h1>
    <p>Software Engineer · London, UK</p>

    <div className={styles.badges}>
      {data.socialLinks.map(props => (
        <SocialBadge {...props} />
      ))}
    </div>
  </div>
)

export default BusinessCard
